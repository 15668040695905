import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

let API_URL = process.env.REACT_APP_API_URL

function UpdateMember() {
    const navigate = useNavigate()
        const [data, setData] = useState({
            id: null,
            vezeteknev: "",
            utonev: "",
            szuletesi_ido: "",
            irszam: "",
            helyseg: "",
            cim: "",
            telefon: "",
            email: "",
            tagsag_kezdete: ""
        })
    
        useEffect(() => {
            const orig_data = {...data}
            orig_data["id"] = window.localStorage.getItem('id')
            orig_data["vezeteknev"] = window.localStorage.getItem('vezeteknev')
            orig_data["utonev"] = window.localStorage.getItem('utonev')
            orig_data["szuletesi_ido"] = window.localStorage.getItem('szuletesi_ido')
            orig_data["irszam"] = window.localStorage.getItem('irszam')
            orig_data["helyseg"] = window.localStorage.getItem('helyseg')
            orig_data["cim"] = window.localStorage.getItem('cim')
            orig_data["telefon"] = window.localStorage.getItem('telefon')
            orig_data["email"] = window.localStorage.getItem('email')
            orig_data["tagsag_kezdete"] = window.localStorage.getItem('tagsag_kezdete')
            setData(orig_data)
        }, [])

        const handle = (e) => {
            const newData = {...data}
            newData[e.target.id] = e.target.value
            setData(newData)
    
        }
    
        const handleSubmit = () => {
            
            const api_url = `${API_URL}/members/${data.id}/`
            
            axios({
                method: "PUT",
                url: api_url,
                data: {
                    id: data.id,
                    vezeteknev: data.vezeteknev,
                    utonev: data.utonev,
                    szuletesi_ido: data.szuletesi_ido,
                    irszam: data.irszam,
                    helyseg: data.helyseg,
                    cim: data.cim,
                    telefon: data.telefon,
                    email: data.email,
                    tagsag_kezdete: data.tagsag_kezdete,
                    aktiv: true
                }
            })
            .then((response) => {
                console.log("valasz: ",response.status)
                navigate('/')
            })
        }
    
      return (
        <div className='member-form'>
            <div className='vezeteknev-row'>
                <label className='form-label'>Vezetéknév</label>
                <input
                    type='text'
                    autoFocus
                    id='vezeteknev'
                    value={data.vezeteknev}
                    placeholder='Vezetéknév...'
                    onChange={(e) => handle(e)}
                />
            </div>  
            <div className='form-row'>
                <label className='utonev-label'>Utónév</label>
                <input
                    type='text'
                    id='utonev'
                    value={data.utonev}
                    placeholder='Utónév...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='szuletesi-ido-label'>Születési idő</label>
                <input
                    type='text'
                    id='szuletesi_ido'
                    value={data.szuletesi_ido}
                    placeholder='Születési idő...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='irszam-label'>Irányítószám</label>
                <input
                    type='text'
                    id='irszam'
                    value={data.irszam}
                    placeholder='Irányítószám...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='helyseg-label'>Helység</label>
                <input
                    type='text'
                    id='helyseg'
                    value={data.helyseg}
                    placeholder='Helység...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='cim-label'>Lakcím</label>
                <input
                    type='text'
                    id='cim'
                    value={data.cim}
                    placeholder='Lakcím...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='telefon-label'>Telefon</label>
                <input
                    type='text'
                    id='telefon'
                    value={data.telefon}
                    placeholder='Telefon...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='email-label'>Email cím</label>
                <input
                    type='text'
                    id='email'
                    value={data.email}
                    placeholder='Email...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='tagsag-kezdete-label'>Tagság kezdete</label>
                <input
                    type='text'
                    id='tagsag_kezdete'
                    value={data.tagsag_kezdete}
                    placeholder='tagság kezdete...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='submit-container'>
                <button className='submit-button' onClick={handleSubmit}>Mentés</button>
            </div>
        </div>
      )
    }

export default UpdateMember
