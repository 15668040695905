import React, { useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { LoggedContext } from '../../App'
import axios from "axios"
import "./Login.css"

let API_URL = process.env.REACT_APP_API_URL


function Login() {
    const {loggedUserId, setLoggedUserId, loggedUserName, setLoggedUserName} = useContext(LoggedContext)
    const navigate = useNavigate()
    const loginTime = new Date()
    const [loginName, setLoginName] = useState(null)
    const [password, setPassword] = useState(null)
    const [message, setMessage] = useState("")

    const handleInputChange = (e) => {
        const {name, value} = e.target 
        if (name === "loginname") {
            setLoginName(value)
        }
        if (name === "password") {
            setPassword(value)
        }
    }

    const handleCancelClick = () => {
        navigate('/')
    }

    const handleLoginClick = async () => {
        /* console.log("Név: ", loginName)
        console.log("Jelszó: ", password) */
        setMessage("")
        try {
            const response = await axios.get(`${API_URL}/login/${loginName}/${password}`)
            console.log(response.data.id)
            if (response.data.id) {
                setLoggedUserId(response.data.id)
                setLoggedUserName(response.data.loginnev)
                window.localStorage.setItem('LoggedUserId', response.data.id)
                window.localStorage.setItem('loggedUserName', response.data.loginnev)
                window.localStorage.setItem('login_time', loginTime.getTime())
                navigate("/")
            } else {
                setMessage(response.data.message)
            }
        } catch (error) {
            setMessage("Érvénytelen felhasználó vagy jelszó!")
        }

    }



  return (
    <div className='login-page'>
      <div className='my-login-form'>
        <div className='login-error-message'>
            {message}
        </div>
        <div className='login-name'>
            <label htmlFor='loginname'>Felhasználó név:</label>
            <input
                type='text'
                autoFocus
                name='loginname'
                value={loginName}
                placeholder='Felhasználó...'
                onChange = {(e) => handleInputChange(e)}
            />
        </div>
        <div className='password'>
            <label htmlFor='password'>Jelszó:</label>
            <input
                type='password'
                name='password'
                value={password}
                placeholder='Jelszó...'
                onChange = {(e) => handleInputChange(e)}
            />
        </div>
        <div className='buttons'>
            
            <button onClick={handleLoginClick} className='btn-login'>Belépés</button>
            <button onClick={handleCancelClick} className='btn-login'>Mégsem</button>
        </div>
      </div>
    </div>
  )
}



export default Login
