import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoggedContext } from '../../App'

const Logout = ({}) => {
    const {loggedUserId, setLoggedUserId, loggedUserName, setLoggedUserName} = useContext(LoggedContext)
    const navigate = useNavigate()

    useEffect(() => {
        setLoggedUserId(null)
        setLoggedUserName("")
        navigate("/")
        window.localStorage.removeItem('LoggedUserId', null)
        window.localStorage.removeItem('loggedUserName', "")
        window.localStorage.removeItem('login_time', 0)
    }, [])

    return null

}

export default Logout