import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import "./Header.css"

function Header({loggedUserId=null, loggedUserName=""}) {
  return (
    <div className='my_header'>
        <div className='menuk'>
            {loggedUserId > 0 
            ? 
            <div className='navigation'>
                <Link to="/members/">Tagok</Link>
            </div>            
            : 
            ""
            }
        </div>
        <div className='login_corner'>
        {loggedUserId > 0 ? 
            <Link to="/logout/">Kilépés</Link>
        : 
        <>
            <Link to="/login/">Belépés</Link>
        </>
        }
        </div>
    </div>
  )
}

export default Header
