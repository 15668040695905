import { useEffect, useState, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import './App.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Logout from "./components/Logout/Logout";
import Members from "./pages/Members/Members";
import CreateMember from "./components/CRUD/CreateMember";
import UpdateMember from "./components/CRUD/UpdateMember";
import MemberTable from "./components/CrudTable/MemberTable";
import ReactTable from "./components/ReactTable/ReactTable";

export const LoggedContext = createContext(null)

function App() {
  const [loggedUserId, setLoggedUserId] = useState(null)
  const [loggedUserName, setLoggedUserName] = useState("")
  const [loginTime, setLoginTime] = useState()

  useEffect(() => {
    setLoggedUserId(window.localStorage.getItem('LoggedUserId'))
    setLoggedUserName(window.localStorage.getItem('loggedUserName'))
    setLoginTime(window.localStorage.getItem('loginTime'))
  }, [])

  console.log("Reload: userId: ", loggedUserId)
  console.log("Reload: userName: ", loggedUserName)

  return (
    <BrowserRouter>
      <LoggedContext.Provider value={{
        loggedUserId,
        setLoggedUserId,
        loggedUserName,
        setLoggedUserName
      }}>
        <div className="main_page">
          <Header loggedUserId={loggedUserId} loggedUserName={loggedUserName} />
          <Routes>
            <Route path="/createmember/" element={<CreateMember/>} />
            <Route path="/updatemember/" element={<UpdateMember/>} />
            <Route path="/login/" element={<Login/>} />
            <Route path="/logout/" element={<Logout/>} />
            <Route path="/" element={<Home/>} />
            <Route path="/members" element={<ReactTable/>} />


          </Routes>
          <Footer />
        </div>
      </LoggedContext.Provider>
    </BrowserRouter>
  );
}

export default App;
