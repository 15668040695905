import React from 'react'
import "./Footer.css"

function Footer() {
  return (
    <div className='my_footer'>
      Footer
    </div>
  )
}

export default Footer
