import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell} from '@table-library/react-table-library/table';
import { HeaderCellSelect, CellSelect, SelectClickTypes, SelectTypes, useRowSelect, } from "@table-library/react-table-library/select";
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import "./ReactTable.css"
import { Link, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal'

/* import "bootstrap/dist/css/bootstrap.min.css"; */


let API_URL = process.env.REACT_APP_API_URL

function ReactTable() {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [deleteUser, setDeleteUser] = useState(null)
    
    
    useEffect(() => {
        getMembers()
    }, [])

    function getMembers() {
        axios({
            method: "GET",
            url: `${API_URL}/members/`
        }).then((response) => {
            const new_data = response.data
            setTableData(new_data)
            console.log("beolvasott: ", new_data)
        })
    }

    const handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const handleModify = (id) => {

    }

    const handleRemove = (id) => {
        /* Beállítjuk a törlendő ID-t */
        setDeleteId(id)
        const temp_delete = tableData.filter((item) => item.id === id)
        setDeleteUser(temp_delete)
        console.log("deleteuser: ", temp_delete)
        /* Megjelenítjük a DIALOG-ot */
        setShowModal(true)
        /* Itt most csak a már beolvasott data-bol törlünk, valójában majd axios-al, api-n keresztül */
        /* const tempData = tableData.filter((item) => item.id !== id)
        setTableData(tempData) */
        
    }

    /* Ha megszakit: null ______ Ha OK: deletId */
    const handleModalClose = (id) => {
        
        if (id) {
            console.log("Törlendő: ", id)
            const api_url = `${API_URL}/members/${id}/`
            axios({
                method: "DELETE",
                url: api_url
            })
            .then((response) => {
                console.log("valasz: ",response.status)
                /*  ujratöltés*/
                getMembers()
                setDeleteUser(null)
            })
        } else {
            console.log("Törlendő: ", "Nincs megerősítve")
        }
        setShowModal(false)
    }
    

    const data = { 
        nodes: tableData.filter((item) => 
        item.vezeteknev.toLowerCase().includes(search.toLowerCase()) || item.utonev.toLowerCase().includes(search.toLowerCase())
        ),}

    const select = useRowSelect(data, {
        onChange: onSelectChange
    })

    function onSelectChange(action, state) {
        console.log(action, state)
        /* a state-ben benn az id !!!! törléshez módosításhoz jó lesz */
    }

    const sort = useSort(data, 
        {
            state: {
                sortKey: 'VEZNEV',
                reverse: false,
            },
            onChange: onSortChange,
        }, {
        sortFns: {
            VEZNEV: (array) => array.sort((a, b) => a.vezeteknev.localeCompare(b.vezeteknev)),
            UTONEV: (array) => array.sort((a, b) => a.utonev.localeCompare(b.utonev)),
            SZUL: (array) => array.sort((a, b) => a.szuletesi_ido - b.szuletesi_ido),
            IRSZ: (array) => array.sort((a, b) => a.irszam.localeCompare(b.irszam)),
            HELYSEG: (array) => array.sort((a, b) => a.helyseg.localeCompare(b.helyseg)),
            CIM: (array) => array.sort((a, b) => a.cim.localeCompare(b.cim)),
            TEL: (array) => array.sort((a, b) => a.telefon.localeCompare(b.telefon)),
            EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
            TAGSAG: (array) => array.sort((a, b) => a.tagsag_kezdete - b.tagsag_kezdete),
        }
    })
    
    function onSortChange(action, state) {
        console.log(action, state)
    }

    const THEME = {
        HeaderRow: `
          font-size: 16px;
      
          background-color: #eaf5fd;
        `,
        Row: `
          font-size: 14px;
      
          &:nth-child(odd) {
            background-color: #d2e9fb;
          }
      
          &:nth-child(even) {
            background-color: #eaf5fd;
          }
        `,
      };

    const theme = useTheme(THEME)

    return (
        <div className='member-page'>
            <div className='content'>
            <ReactModal 
                isOpen={showModal} 
                contentLabel='Minimál'
                /* confirmModal={confirmModal} */
                className='Modal-dialog'
                overlayClassName='Overlay'
                
            > 
            <div className='modal-content'>
                <p className='warning-title'>Figyelmeztetés!</p>
                <div className='warning-text'>
                {deleteUser && <p className='warning'>
                       Biztosan törölni akarja a <strong> {deleteUser[0].vezeteknev}  {deleteUser[0].utonev} </strong> nevű tagot?
                    </p>}
                    
                       
                </div>
                <div className='buttons'>
                    <button onClick={() => handleModalClose(null)}>Cancel</button>
                    <button onClick={() => handleModalClose(deleteId)}>OK</button>
                </div>
                


            </div>
                
                {/* <button onClick={confirmModal(deleteId)}>OK</button> */}

            </ReactModal>
            
            <div className='table-actions'>
                <>
                    <label htnlFor="search"  className='search-field'>
                        Kereseés névre:
                        <div>
                            <input id="search" type="text" value={search} onChange={handleSearch}/>
                        </div>
                        
                    </label>
                </>
                <>
                    <Link to='/createmember/'><button>Új tag</button></Link>
                </>
            </div>
            
            <div className='table'>
            <Table data={data} theme={theme} sort={sort} select={select}>
                {(tableList) => (
                    <>
                        <Header>
                        <HeaderRow>
                            <HeaderCellSort sortKey='VEZNEV'>Vezetéknév</HeaderCellSort>
                            <HeaderCellSort sortKey='UTONEV'>Utónév</HeaderCellSort>
                            <HeaderCellSort sortKey='SZUL'>Születési idő</HeaderCellSort>
                            <HeaderCellSort sortKey='IRSZ'>Irányítószám</HeaderCellSort>
                            <HeaderCellSort sortKey='HELYSEG'>Helység</HeaderCellSort>
                            <HeaderCellSort sortKey='CIM'>Cím</HeaderCellSort>
                            <HeaderCellSort sortKey='TEL'>Telefon</HeaderCellSort>
                            <HeaderCellSort sortKey='EMAIL'>Email</HeaderCellSort>
                            <HeaderCellSort sortKey='TAGSAG'>Tagság kezdete</HeaderCellSort>  
                            <HeaderCell>Művelet</HeaderCell>
                            {/* <HeaderCell>M</HeaderCell> */}
                        </HeaderRow>
                        </Header>
                        <Body>
                        {tableList.map((item) => (
                        <Row key={item.id} item={item}>
                            <Cell>{item.vezeteknev}</Cell>
                            <Cell>{item.utonev}</Cell>
                            <Cell>{item.szuletesi_ido}</Cell>
                            <Cell>{item.irszam}</Cell>
                            <Cell>{item.helyseg}</Cell>
                            <Cell>{item.cim}</Cell>
                            <Cell>{item.telefon}</Cell>
                            <Cell>{item.email}</Cell>
                            <Cell>{item.tagsag_kezdete}</Cell>
                            <Cell>
                                <button type="button" id='delete-button' onClick={() => handleRemove(item.id)}>T</button>
                                <button type="button" id='modify-button' onClick={() => handleModify(item.id)}>M</button>
                            </Cell>
                            
                        </Row>
                        ))}
                    </Body>
                </>
            )}
        </Table>
        </div>
        </div>
        <div className='spacer'>

        </div>
      </div>
    )
  
  
}



export default ReactTable
