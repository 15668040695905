import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./CreateMember.css"

let API_URL = process.env.REACT_APP_API_URL

function CreateMember() {
    const navigate = useNavigate()
    const [data, setData] = useState({
        vezeteknev: "",
        utonev: "",
        szuletesi_ido: "",
        irszam: "",
        helyseg: "",
        cim: "",
        telefon: "",
        email: "",
        tagsag_kezdete: ""
    })

    const handle = (e) => {
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)

    }

    const handleCancelClick = () => {
        navigate("/members")
    }

    const handleSubmit = () => {
        
        const api_url = `${API_URL}/members/`
        
        axios({
            method: "POST",
            url: api_url,
            data: {
                vezeteknev: data.vezeteknev,
                utonev: data.utonev,
                szuletesi_ido: data.szuletesi_ido,
                irszam: data.irszam,
                helyseg: data.helyseg,
                cim: data.cim,
                telefon: data.telefon,
                email: data.email,
                tagsag_kezdete: data.tagsag_kezdete,
                aktiv: true
            }
        })
        .then((response) => {
            console.log("valasz: ",response.status)
            navigate("/members")
        })
    }

  return (
    <div className='create-member-page'>
        <div className='member-form'>
            <div className='form-row'>
                <label className='form-label'>Vezetéknév</label>
                <input
                    type='text'
                    autoFocus
                    id='vezeteknev'
                    value={data.vezeteknev}
                    placeholder='Vezetéknév...'
                    onChange={(e) => handle(e)}
                />
            </div>  
            <div className='form-row'>
                <label className='utonev-label'>Utónév</label>
                <input
                    type='text'
                    id='utonev'
                    value={data.utonev}
                    placeholder='Utónév...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='szuletesi-ido-label'>Születési idő</label>
                <input
                    type='text'
                    id='szuletesi_ido'
                    value={data.szuletesi_ido}
                    placeholder='Születési idő...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='irszam-label'>Irányítószám</label>
                <input
                    type='text'
                    id='irszam'
                    value={data.irszam}
                    placeholder='Irányítószám...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='helyseg-label'>Helység</label>
                <input
                    type='text'
                    id='helyseg'
                    value={data.helyseg}
                    placeholder='Helység...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='cim-label'>Lakcím</label>
                <input
                    type='text'
                    id='cim'
                    value={data.cim}
                    placeholder='Lakcím...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='telefon-label'>Telefon</label>
                <input
                    type='text'
                    id='telefon'
                    value={data.telefon}
                    placeholder='Telefon...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='email-label'>Email cím</label>
                <input
                    type='text'
                    id='email'
                    value={data.email}
                    placeholder='Email...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='form-row'>
                <label className='tagsag-kezdete-label'>Tagság kezdete</label>
                <input
                    type='text'
                    id='tagsag_kezdete'
                    value={data.tagsag_kezdete}
                    placeholder='tagság kezdete...'
                    onChange={(e) => handle(e)}
                />
            </div>
            <div className='submit-container'>
                <button className='submit-button' onClick={handleSubmit}>Mentés</button>
                <button onClick={handleCancelClick} className='btn-login'>Mégsem</button>
            </div>
        </div>
    </div>
  )
}

export default CreateMember

